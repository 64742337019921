import React from "react";
import styles from "../../style/Courses_offered.module.css";

const Courses_offered9 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text3}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          ความร่วมมือทางวิชาการ
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            ระหว่างประเทศ
          </h1>
        </h1>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Cambodia</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>
            - Royal University of Phnom Penh, Cambodia
          </span>

          <span className={styles.text1}>- Royal University of Fine Arts</span>

          <span className={styles.text1}>- Khmer Writers Associate (KWA)</span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>China</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>- Changzhou University</span>

          <span className={styles.text1}>- Sias International University</span>

          <span className={styles.text1}>- Wenzhou University</span>

          <span className={styles.text1}>
            - Guangxi University of Foreign Languages
          </span>

          <span className={styles.text1}>- Chengdu University</span>

          <span className={styles.text1}>
            - Dalian University of Foreign Languages (DUFL)
          </span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>France</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>- Universite’ de La Rochelle</span>

          <span className={styles.text1}>- University of Avignon</span>

          <span className={styles.text1}>- University of Franche Comte</span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Japan</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>- Aichi University</span>

          <span className={styles.text1}>- Osaka International University</span>

          <span className={styles.text1}>- Takushoku University</span>

          <span className={styles.text1}>- Shinshu University</span>

          <span className={styles.text1}>- Reitaku University</span>

          <span className={styles.text1}>- Kanazawa University</span>

          <span className={styles.text1}>- Chiba University</span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Korea</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>- Mokwon University</span>

          <span className={styles.text1}>- Dongseo University</span>

          <span className={styles.text1}>- Honam University</span>

          <span className={styles.text1}>
            - Busan University of Foreign Studies (BUFS)
          </span>

          <span className={styles.text1}>- EWHA Womans University</span>

          <span className={styles.text1}>
            - Cyber Hankuk University of Foreign Studies
          </span>

          <span className={styles.text1}>- Chungnam National University</span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Laos</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>- National University of Laos</span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Malaysia</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>
            - International Islamic University
          </span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Myanmar</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>
            - Yangon University of Foreign Language, Myanmar (YUFL)
          </span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Taiwan</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>- I-Shou University</span>

          <span className={styles.text1}>- Fo Guang University</span>

          <span className={styles.text1}>- National Chi Nan University</span>
        </div>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>Vietnam</h1>

        <div className={styles.box_text1}>
          <span className={styles.text1}>
            - Hue University College of Foreign Language
          </span>
        </div>
      </div>

      <br />

      <div style={{ width: "100%", height: 50 }}>&nbsp;</div>
    </div>
  );
};

export default Courses_offered9;
