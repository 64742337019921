import React from "react";
import styles from "../../style/Courses_offered.module.css";
import CoursesCard from "../../components/card/card_for_courses";

const Courses_offered7 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text2}>
        <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
          ค่าธรรมเนียม
          <h1 className={styles.text_head} style={{ color: "#666666" }}>
            การศึกษา
          </h1>
        </h1>
      </div>

      <br />
      <br />

      <CoursesCard
        text1={"ระดับปริญญาตรี"}
        text2={"สิตสายภาษา ภาคเรียนละ 15,000 บาท"}
        text3={"นิสิตภาควิชาดนตรี และศิลปะการแสดง ภาคเรียนละ 15,000 บาท"}
      />
      <CoursesCard
        text1={"ระดับปริญญาโท"}
        text2={"แบบ 2 ภาคการศึกษา ตลอดหลักสูตร 90,000 บาท"}
        text3={"แบบ 3 ภาคการศึกษา ตลอดหลักสูตร 120,000 บาท"}
      />
      <CoursesCard
        text1={"ระดับปริญญาเอก"}
        text2={"แบบ 2 ภาคการศึกษา ตลอดหลักสูตร 180,000 บาท"}
      />
    </div>
  );
};

export default Courses_offered7;
