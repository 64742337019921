import React from "react";
import styles from "../style/Service.module.css";
import image_service from '../images/image_service.png'

const Service = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_head}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          บริ
        </h1>
        <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
          การ
        </h1>
      </div>
      <img src={image_service} alt="image_service" className={styles.image}/>
    </div>
  );
};

export default Service;
