import React from "react";
import styles from "../../style/Courses_offered.module.css";

const Courses_offered6 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text2}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          กำหนดการ
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            รับสมัคร
          </h1>
        </h1>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>ระดับปริญญาตรี</h1>
        <div className={styles.box_text1} style={{ marginLeft: 55 }}>
          <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
            รอบที่ 1 (Portfolio)
          </h1>
          <span className={styles.text1}>
            - รับสมัครช่วงเดือนตุลาคม-พฤศจิกายน
          </span>

          <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
            รอบที่ 2 (โควตา)
          </h1>
          <span className={styles.text1}>
            - รับสมัครช่วงเดือน พฤศจิกายน-กุมภาพันธ
          </span>

          <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
            รองที่ 3 (Admission)
          </h1>
          <span className={styles.text1}>- รับสมัครช่วงเดือนพฤษภาคม</span>

          <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
            รอบที่ 4 (รับตรงอิสระ)
          </h1>
          <span className={styles.text1}>- รับสมัครช่วงเดือนพฤษภาคม</span>
        </div>

        <h1 className={styles.text_head_sub} style={{ marginTop: 20 }}>
          ระดับปริญญาโท
        </h1>
        <span
          className={styles.text1}
          style={{ marginLeft: 55, marginTop: 20 }}
        >
          เปิดรับตลอดปีการศึกษา
        </span>

        <div className={styles.box_text2} style={{ marginTop: 20 }}>
          <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
            หมายเหตุ :
          </h1>
          <span className={styles.text1}>
            &nbsp; สามารถดูรายละเอียดเพิ่มเติมได้ที่
          </span>
        </div>
        <br />
        {/* <a href="http://www.acad.nu.ac.th/acad_admission/" className={styles.text1}>http://www.acad.nu.ac.th/acad_admission/</a> */}
        <span className={styles.text1}>
          http://www.acad.nu.ac.th/acad_admission/
        </span>
      </div>
    </div>
  );
};

export default Courses_offered6;
