import React from "react";
import styles from "../style/Courses_card.module.css";
import TdesignMoney from "../../images/tdesign_money.svg";

const Courses_card = (props: any) => {
  // console.log(text1.text1);
  const { text1, text2, text3 } = props;

  return (
    <div className={styles.card}>
      <img src={TdesignMoney} alt={"icon"} className={styles.icon} />
      <div className={styles.box_text}>
        <h1 className={styles.text_head}>{text1}</h1>
        <span className={styles.text1}>{text2}</span>
      </div>
      {text3 ? (
        <div className={styles.box_text2}>
          <div className={styles.line}>
            
          </div>
          <div className={styles.box_text}>
            <span className={styles.text1}>{text3}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Courses_card;
