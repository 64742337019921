import { Navigate, useRoutes } from "react-router-dom";
import Service from "./page/Service";
import About from "./page/About";
import CoursesOffered from "./page/Courses_offered";
import ReportAcomplaint from "./page/Report_a_complaint";

import CoursesOffered1 from "./page/Courses_offered/Courses_offered1";
import CoursesOffered2 from "./page/Courses_offered/Courses_offered2";
import CoursesOffered3 from "./page/Courses_offered/Courses_offered3";
import CoursesOffered4 from "./page/Courses_offered/Courses_offered4";
import CoursesOffered5 from "./page/Courses_offered/Courses_offered5";
import CoursesOffered6 from "./page/Courses_offered/Courses_offered6";
import CoursesOffered7 from "./page/Courses_offered/Courses_offered7";
import CoursesOffered8 from "./page/Courses_offered/Courses_offered8";
import CoursesOffered9 from "./page/Courses_offered/Courses_offered9";

import Android from "./page/Setting/Android";
import IOS from "./page/Setting/IOS";

export default function Router() {
  const routes = useRoutes([
    { element: <Navigate to="/About" replace />, index: true },
    { path: "About", element: <About /> },

    { path: "Courses_offered", element: <CoursesOffered /> },

    { path: "Report_a_complaint", element: <ReportAcomplaint /> },

    { path: "Service", element: <Service /> },

    { path: "Courses_offered1", element: <CoursesOffered1 /> },
    { path: "Courses_offered2", element: <CoursesOffered2 /> },
    { path: "Courses_offered3", element: <CoursesOffered3 /> },
    { path: "Courses_offered4", element: <CoursesOffered4 /> },
    { path: "Courses_offered5", element: <CoursesOffered5 /> },
    { path: "Courses_offered6", element: <CoursesOffered6 /> },
    { path: "Courses_offered7", element: <CoursesOffered7 /> },
    { path: "Courses_offered8", element: <CoursesOffered8 /> },
    { path: "Courses_offered9", element: <CoursesOffered9 /> },

    { path: "Android", element: <Android /> },
    { path: "IOS", element: <IOS /> },
  ]);

  return routes;
}
