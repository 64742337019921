import React from "react";
import styles from "../style/Circle_btn.module.css";
import Graduate from "../../images/graduate.svg";

const Circle_btn = (props: any) => {
  // console.log(text1.text1);
  const { text1, onClick } = props;

  return (
    <div className={styles.container} onClick={onClick}>
      <img src={Graduate} alt="Logo Graduate" className={styles.logo} />
      <span className={styles.text1}>หลักสูตร</span>
      <h1 className={styles.text2}>{text1}</h1>
    </div>
  );
};

export default Circle_btn;
