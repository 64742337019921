import React from "react";
import styles from "../../style/Setting.module.css";
import Export from "../../images/export.png";
import android1 from "../../images/setting/android/android_1.jpg";
import android2 from "../../images/setting/android/android_2.jpg";
import android3 from "../../images/setting/android/android_3.jpg";
import android4 from "../../images/setting/android/android_4.jpg";
import android5 from "../../images/setting/android/android_5.jpg";
import android6 from "../../images/setting/android/android_6.jpg";
import android7 from "../../images/setting/android/android_7.jpg";
import android8 from "../../images/setting/android/android_8.jpg";
import PDF from "../../files/Android.pdf";

const Android = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = PDF;
    link.download = PDF;
    link.click();
  };

  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
      }}
    >
      <div className={styles.box_head}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          วิธีเปิดแจ้งเตือน
        </h1>
        <h1 className={styles.text_head} style={{ color: "#2FBA67" }}>
          สำหรับ ANDROID
        </h1>
      </div>
      <div className={styles.view_alert}>
        <h1 className={styles.text_alert} style={{ color: "#E34545" }}>
          *สำหรับโทรศัพท์อัปเดต หรือยังไม่เคยตั้งค่าเครื่อง
          ลองตั้งค่าตามขั้นตอนใหม่ด้วยเช่นกัน
        </h1>
      </div>

      <div className={styles.btn_view}>
        <button
          className={styles.export_btn}
          style={{ backgroundColor: "#2FBA67" }}
          onClick={() => {
            handleDownload();;
          }}
        >
          <div className={styles.inBtn_view} style={{ width: "15%" }}>
            <img src={Export} className={styles.icon} alt="icon 1" />
          </div>

          <div style={{ width: "85%" }}>
            <h1 className={styles.text_btn}>
              ดาวน์โหลดไฟล์วิธีเปิดแจ้งเตือน (PDF)
            </h1>
          </div>
        </button>
      </div>

      <img src={android1} className={styles.image} alt="icon 2" />
      <img src={android2} className={styles.image} alt="icon 3" />
      <img src={android3} className={styles.image} alt="icon 4" />
      <img src={android4} className={styles.image} alt="icon 5" />
      <img src={android5} className={styles.image} alt="icon 6" />
      <img src={android6} className={styles.image} alt="icon 7" />
      <img src={android7} className={styles.image} alt="icon 8" />
      <img src={android8} className={styles.image} alt="icon 9" />
    </div>
  );
};

export default Android;
