import React from "react";
import styles from "../../style/Courses_offered.module.css";

const Courses_offered1 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text2}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          หลักสูตรระดับ
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            ปริญญาตรี
          </h1>
        </h1>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>
          ศิลปศาสตรบัณฑิต จำนวน 8 หลักสูตร
        </h1>

        <div className={styles.box_text_2page}>
          <div className={styles.in_box_2page}>
            <span className={styles.text1}> - ศศ.บ. (ภาษาอังกฤษ)</span>
            <span className={styles.text1}> - ศศ.บ. (ภาษาไทย)</span>
            <span className={styles.text1}> - ศศ.บ. (ภาษาฝรั่งเศส)</span>
            <span className={styles.text1}> - ศศ.บ. (ภาษาจีน)</span>
          </div>

          <div className={styles.in_box_2page}>
            <span className={styles.text1}> - ศศ.บ. (ภาษาอังกฤษ)</span>
            <span className={styles.text1}> - ศศ.บ. (ภาษาไทย)</span>
            <span className={styles.text1}> - ศศ.บ. (ภาษาฝรั่งเศส)</span>
            <span className={styles.text1}> - ศศ.บ. (ภาษาจีน)</span>
          </div>
        </div>

        <h1 className={styles.text_head_sub}>
          ดุริยางคศาสตรบัณฑิต จำนวน 1 หลักสูตร
        </h1>
        <br />
        <span className={styles.text1}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            "ดศ.บ. (วิชาเอกดนตรีตะวันตกและวิชาเอกดนตรีไทย) โดยทุกหลักสูตรทางด้านภาษาต้องเลือกเรียนกลุ่มวิชาโทจำนวนไม่น้อยกว่า 18 หน่วยกิต จากกลุ่มวิชาใดวิชาหนึ่งดังนี้ "
          }
        </span>

        <div className={styles.box_text_2page}>
          <div className={styles.in_box_2page}>
            <span className={styles.text1}> - กลุ่มวิชาภาษาอังกฤษ</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาจีน</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาญี่ปุ่น</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาไทย</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาพม่า</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาฝรั่งเศส</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาเกาหลี</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาเวียดนาม</span>
          </div>

          <div className={styles.in_box_2page}>
            <span className={styles.text1}> - กลุ่มวิชาภาษาอินโดนีเซีย</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาลาว</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาศาสตร์</span>
            <span className={styles.text1}> - กลุ่มวิชาปรัชญาและศาสนา</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาสเปน</span>
            <span className={styles.text1}> - กลุ่มวิชาภาษาเขมร</span>
            <span className={styles.text1}> - กลุ่มวิชาคติชนวิทยา</span>
            <span className={styles.text1}> - กลุ่มวิชาประวัติศาสตร์</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses_offered1;
