import React from "react";
import styles from "../../style/Courses_offered.module.css";

const Courses_offered4 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text2}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          ระบบ
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            การศึกษา
          </h1>
        </h1>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>ระดับปริญญาตรี</h1>
        <div className={styles.box_text1}>
          <span className={styles.text1}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {
              "จัดการเรียนการสอนปีละ 2 ภาคเรียน เรียนวันจันทร์ถึงวันศุกร์ในเวลาราชการ"
            }
          </span>
        </div>

        <br />
        <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
          ระดับบัณฑิตศึกษา
        </h1>

        <div className={styles.box_text1} style={{ marginLeft: 55 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h1 className={styles.text_head_sub}>ระดับปริญญาโท </h1>
            &nbsp;
            <span className={styles.text1}>แบ่งการศึกษาเป็น 2 แผน คือ</span>
          </div>

          <div className={styles.box_text1} style={{ marginLeft: 55 }}>
            <span className={styles.text1}>
              {" "}
              - เน้นการวิจัย (หลักสูตรแผน 1 ว. 2)
            </span>
            <span className={styles.text1}>
              {" "}
              - เน้นการทำวิจัยและศึกษารายวิชา (หลักสูตรแผน 2 แบบวิชาชีพ)
              จัดการเรียนการสอน แบบ 2 ภาคการศึกษา
            </span>

            <div className={styles.box_text1} style={{ marginLeft: 55 }}>
              <span className={styles.text1}>
                {" "}
                - เน้นการวิจัย (หลักสูตรแผน 1 ว. 2)
              </span>
              <span className={styles.text1}>
                {" "}
                - เน้นการทำวิจัยและศึกษารายวิชา (หลักสูตรแผน 2 แบบวิชาชีพ)
                จัดการเรียนการสอน แบบ 2 ภาคการศึกษา
              </span>
            </div>
          </div>
        </div>

        <div className={styles.box_text1} style={{ marginLeft: 55 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h1 className={styles.text_head_sub}>ระดับปริญญาเอก </h1>
            &nbsp;
            <span className={styles.text1}>แบ่งการศึกษาเป็น 2 แผน คือ</span>
          </div>

          <div className={styles.box_text1} style={{ marginLeft: 55 }}>
            <span className={styles.text1}>
              {" "}
              - เน้นการวิจัย (แผน 1.1) จำนวนหน่วยกิตรวมตลอด หลักสูตรไม่น้อยกว่า
              48 หน่วยกิตระยะเวลาการ ศึกษา 3 ปี (6 ภาคการศึกษา)
            </span>
            <span className={styles.text1}>
              {" "}
              - แผน 2.1 เน้นการทำวิจัยและศึกษารายวิชา จำนวน
              หน่วยกิตรวมตลอดหลักสูตรไม่น้อยกว่า 48 หน่วยกิต ระยะเวลาการศึกษา 3
              ปี (6 ภาคการศึกษา)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses_offered4;
