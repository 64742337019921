import React from "react";
import styles from "../style/Courses_offered.module.css";
import LogoHuman from "../images/LogoHUMAN.svg";
import CircleBtn from "../components/btn/Circle_btn";
import CircleBtn2 from "../components/btn/Circle_btn2";

import Book from "../images/book.svg";
import En from "../images/en.svg";
import Calenda from "../images/calenda.svg";
import Onehand from "../images/onehand.svg";
import Money from "../images/money.svg";
import Twohand from "../images/twohand.svg";

const Courses_offered = () => {
  const Goto_SupPage = (page: any) => {
    // const {} = props;
    console.log("/Courses_offered" + page);
    console.log(page);
    window.open("/Courses_offered" + page);
  };

  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <img src={LogoHuman} className={styles.icon} alt="Logo Human" />

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>ปรัชญา</h1>
        <span className={styles.text1}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            "มนุษยศาสตร์โน้มนำให้มนุษย์เข้าใจแก่นแท้ของตนเองเพื่ออยู่ร่วมกันอย่างสันติและเป็นอิสระจากอวิชชา  "
          }
        </span>
        <h1 className={styles.text_head_sub}>ปณิธาน</h1>
        <span className={styles.text1}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            "คณะมนุษยศาสตร์มุ่งมั่นสรรค์สร้างทรัพยากรมนุษย์ให้มีคุณภาพ และศักยภาพทั้งด้านความรู้ สติปัญญาและคุณธรรมจริยธรรม เสริมสร้างความเข้าใจอันดีในสังคมไทยและสังคมโลก เพื่อพัฒนาอย่างสันติและยั่งยืน "
          }
        </span>
      </div>

      <div className={styles.box_head}>
        <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
          หลักสูตร
        </h1>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          ที่เปิดสอน
        </h1>
      </div>

      <div className={styles.box_btn_circle}>
        <CircleBtn
          text1="ปริญญาตรี"
          onClick={() => {
            Goto_SupPage(1);
          }}
        />
        <CircleBtn
          text1="ปริญญาโท"
          onClick={() => {
            Goto_SupPage(2);
          }}
        />
        <CircleBtn
          text1="ปริญญาเอก"
          onClick={() => {
            Goto_SupPage(3);
          }}
        />
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head2}>หมวดหมู่เพิ่มเติม</h1>
      </div>

      <div className={styles.box_btn_circle}>
        <CircleBtn2
          text={"ระบบการศึกษา"}
          icon={Book}
          onClick={() => {
            Goto_SupPage(4);
          }}
        />
        <CircleBtn2
          text="เงื่อนไขเกณฑ์การสอบผ่านความรู้ภาษาอังกฤษ"
          icon={En}
          onClick={() => {
            Goto_SupPage(5);
          }}
        />
        <CircleBtn2
          text="กำหนดการรับสมัคร"
          icon={Calenda}
          onClick={() => {
            Goto_SupPage(6);
          }}
        />
      </div>

      <div className={styles.box_btn_circle}>
        <CircleBtn2
          text="ค่าธรรมเนียมการศึกษา"
          icon={Onehand}
          onClick={() => {
            Goto_SupPage(7);
          }}
        />
        <CircleBtn2
          text="ทุนการศึกษา บริการและสวัสดิ การนิสิต "
          icon={Money}
          onClick={() => {
            Goto_SupPage(8);
          }}
        />
        <CircleBtn2
          text="ความร่วมมือทางวิชาการระหว่างประเทศ "
          icon={Twohand}
          onClick={() => {
            Goto_SupPage(9);
          }}
        />
      </div>

      <br />

      <div style={{ width: "100%", height: 50 }}>&nbsp;</div>
    </div>
  );
};

export default Courses_offered;
