import React from "react";
import styles from "../style/Circle_btn.module.css";

const Circle_btn2 = (props: any) => {
  const { text, icon, onClick } = props;
  // console.log(icon);

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.icon_btn}>
        <img src={icon} alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.text_btn}>
        <span className={styles.text3}>{text}</span>
      </div>
    </div>
  );
};

export default Circle_btn2;
