import React from "react";
import styles from "../../style/Courses_offered.module.css";

const Courses_offered8 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text2}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          ทุนการศึกษา
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            &nbsp;บริการ
          </h1>
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            และสวัสดิการนิสิต
          </h1>
        </h1>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub2}>ระดับปริญญาตรี</h1>
        <div className={styles.box_text1} style={{ marginLeft: 55 }}>
          <h1 className={styles.text_head_sub}>1. ทุนการศึกษา</h1>

          <div className={styles.box_text1} style={{ marginLeft: 55 }}>
            <p className={styles.box_text4}>
              <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
                ทุนการศึกษาภายใน
              </h1>
              <span className={styles.text1}>
                เป็นทุนการศึกษาที่ได้รับจาก คณาจารย์
                บุคลากรหรือผู้บริจาคให้เป็นทุนการศึกษา แก่นิสิตมหาวิทยาลัยนเรศวร
              </span>
            </p>

            <p className={styles.box_text4}>
              <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
                ทุนการศึกษาภายนอก
              </h1>
              <span className={styles.text1}>
                เป็นทุนการศึกษาที่ได้จากบุคคล หน่วยงาน มูลนิธิ หรือองค์กรภายนอก
                บริจาคเงิน เพื่อมอบทุนการศึกษาให้แก่นิสิต อาทิ
              </span>
            </p>

            <span className={styles.text1}>- ทุนบุญรอดพัฒนานิสิต นักศึกษา</span>
            <span className={styles.text1}>- ทุน The Set Foundation</span>
            <span className={styles.text1}>- ทุนมูลนิธิทิสโก้เพื่อการกุศล</span>
            <span className={styles.text1}>- ทุนมูลนิธิบางกอกโพสต</span>
            <span className={styles.text1}>- ทุนมูลนิธิราชประชานุเคราะห์ฯ</span>
            <span className={styles.text1}>
              - ทุนมูลนิธิอาจารย์บุญเรือน เอี่ยมสกุล
            </span>
            <span className={styles.text1}>
              - ทุนมูลนิธิหอการค้าอเมริกัน-ไทย
            </span>
            <span className={styles.text1}>- ทุนในพระราชานุเคราะห์</span>
            <span className={styles.text1}>- ทุนเฉลิมราชกุมาร</span>
            <span className={styles.text1}>- ทุนเครือเจริญโภคภัณฑ์ (CP)</span>
            <span className={styles.text1}>- ทุนการศึกษานิสิตพิการ</span>
            <span className={styles.text1}>- ทุนสมเด็จพระบรมโอรสาธิราชฯ</span>
            <span className={styles.text1}>
              - ทุนบริษัทเหล็กสยามยามาโตะ ฯลฯ
            </span>
          </div>

          <h1 className={styles.text_head_sub} style={{ marginTop: 20 }}>
            2. กองทุนกู้ยืมเพื่อการศึกษา
          </h1>

          <div className={styles.box_text1} style={{ marginLeft: 55 }}>
            <span className={styles.text1}>
              - กองทุนให้กู้ยืมเพื่อการศึกษา (กยศ.)
            </span>
            <span className={styles.text1}>- กองทุนฉุกเฉินไทยช่วยไทย</span>
          </div>

          <h1 className={styles.text_head_sub} style={{ marginTop: 20 }}>
            3. ทุนให้ยืมช่วยเหลือนิสิตฉุกเฉิน คณะมนุษยศาสตร์ มหาวิทยาลัยนเรศวร
          </h1>

          <div className={styles.box_text1}>
            <span className={styles.text1}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              เป็นเงินทุนเพื่อให้นิสิตยืมในกรณีฉุกเฉิน โดยนิสิต
              จะต้องปฏิบัติตามข้อกำหนด และแนวปฏิบัติของการยืม
              เงินทุนและชำระคืนภายในระยะเวลาที่กำหนดตามสัญญา
              เพื่อเป็นทุนหมุนเวียนสำหรับ ให้ความช่วยเหลือนิสิตราย อื่น ๆ
              ตามความจำเป็น 
            </span>
          </div>

          <h1 className={styles.text_head_sub} style={{ marginTop: 20 }}>
            4. ทุนแลกเปลี่ยนนิสิตไปต่างประเทศระยะสั้น และระยะยาว
          </h1>
          <h1 className={styles.text_head_sub} style={{ marginTop: 20 }}>
            5. ทุนการปฏิบัติสหกิจศึกษาต่างประเทศ
          </h1>
          <h1 className={styles.text_head_sub} style={{ marginTop: 20 }}>
            6. ทุนสนับสนุนการเข้าร่วมแข่งขัน/ประกวด/กิจกรรม ทางวิชาการ 
          </h1>
          <br />
          <h1 className={styles.text_head_sub2}>ทุนการศึกษาระดับบัณฑิตศึกษา</h1>

          <div className={styles.box_text1}>
            <p className={styles.box_text4}>
              <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
                - ทุนการศึกษาภายใน
              </h1>
              <span className={styles.text1}>
                จากงบประมาณ รายได้ของบัณฑิตวิทยาลัย
              </span>
            </p>

            <p className={styles.box_text4}>
              <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
                - ทุนสนับสนุนค่าใช้จ่ายในการนำเสนอผลงานวิจัย
                ในต่างประเทศสำหรับนิสิตระดับบัณฑิตศึกษา (ปริญญาเอก)
              </h1>
              <span className={styles.text1}>
                จากงบประมาณรายได้ของคณะ มนุษยศาสตร์ 
              </span>
            </p>
          </div>

          <br />

          <h1 className={styles.text_head_sub2}>บริการและสวัสดิการนิสิต</h1>

          <div className={styles.box_text1}>
            <p className={styles.box_text4}>
              <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
                - ทุนในกรณีเจ็บป่วย
              </h1>
              <span className={styles.text1}>
                นิสิตสามารถใช้บริการด้าน สุขภาพได้ที่โรงพยาบาลมหาวิทยาลัยนเรศวร
                โดยใช้สิทธิ์บัตรทอง 30 บาท ทั้งนี้ ไม่เสียค่าใช้จ่าย ใด ๆ
                ทั้งสิ้น
              </span>
            </p>

            <p className={styles.box_text4}>
              <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
                - ในกรณีประสบอุบัติเหต
              </h1>
              <span className={styles.text1}>
                นิสิตสามารถใช้สิทธิ์เบิก
                ค่ารักษาพยาบาลได้จากทุนสวัสดิภาพนักเรียนและ
                นิสิตมหาวิทยาลัยนเรศวร 
              </span>
            </p>

            <p className={styles.box_text4}>
              <h1 className={styles.text_head_sub} style={{ color: "#666" }}>
                - บริการรับแจ้งเหตุฉุกเฉินนิสิต 24 ชั่วโมง มหาวิทยาลัยนเรศวร
              </h1>
              <span className={styles.text1}>เบอร์โทรศัพท์ 086-9369977</span>
            </p>
          </div>

          <br />

          <h1 className={styles.text_head_sub2}>กิจกรรมเสริมหลักสูตร</h1>

          <div className={styles.box_text1}>
            <span className={styles.text1}>
              - กิจกรรมพัฒนาศักยภาพด้านการเรียนรู้ และทักษะ ศตวรรษที่ 21
            </span>

            <span className={styles.text1}>
              - กิจกรรมจากหน่วยงานต่าง ๆ ทั้งภายในและภายนอก มหาวิทยาลัย
              ที่เปิดโอกาสให้นิสิตได้ฝึกฝนความรู้ด้านวิชาการและทักษะชีวิต ภาษา
              ดนตรี และนาฏศิลป์ 
            </span>

            <span className={styles.text1}>
              - กิจกรรมการเข้าร่วมแข่งขัน/ประกวด/กิจกรรมทาง วิชาการ 
            </span>
          </div>

          <br />

          <h1 className={styles.text_head_sub2}>สิ่งสนับสนุนการเรียนรู้</h1>

          <div className={styles.box_text1}>
            <span className={styles.text1}>
              - จุดสัญญาณ WIFI ทั่วทั้งคณะและมหาวิทยาลัย 
            </span>

            <span className={styles.text1}>
              - ห้องเรียนปรับอากาศพร้อมอุปกรณ์สื่อโสตทัศนูปกรณ์ ที่ทันสมัย 
            </span>

            <span className={styles.text1}>
              - ห้องอ่านหนังสือประจำคณะ และสำนักหอสมุดอัน ทันสมัยของมหาวิทยาลัย 
            </span>

            <span className={styles.text1}>
              - ห้องปฏิบัติการดนตรีและนาฏศิลป์ 
            </span>

            <span className={styles.text1}>
              - ห้องปฏิบัติการคอมพิวเตอร์ของมหาวิทยาลัย (CITCOMS) 
            </span>

            <span className={styles.text1}>
              - สถานพัฒนาวิชาการด้านภาษาของมหาวิทยาลัย Naresuan University
              Language Center (NULC)
            </span>

            <span className={styles.text1}>- เลานจ์ สำหรับนิสิต</span>
          </div>
        </div>
      </div>

      <br />

      <div style={{ width: "100%", height: 50 }}>&nbsp;</div>
    </div>
  );
};

export default Courses_offered8;
