import React from "react";
import styles from "../../style/Courses_offered.module.css";

const Courses_offered2 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text2}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          หลักสูตรระดับ
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            ปริญญาโท
          </h1>
        </h1>
      </div>

      <div className={styles.box_text1}>
        <h1 className={styles.text_head_sub}>
          ศิลปศาสตรมหาบัณฑิต จำนวน 4 หลักสูตร
        </h1>

        <div className={styles.box_text_2page}>
          <div className={styles.in_box_2page}>
            <span className={styles.text1}> - ศศ.ม. (ภาษาอังกฤษศึกษา)</span>
            <span className={styles.text1}> - ศศ.ม. (ภาษาไทย)</span>
            <span className={styles.text1}> - ศศ.ม. (ภาษาศาสตร์)</span>
            <span className={styles.text1}> - ศศ.ม. (คติชนวิทยา)</span>
          </div>
        </div>

        <h1 className={styles.text_head_sub}>
          ดุริยางคศาสตรบัณฑิต จำนวน 1 หลักสูตร
        </h1>
        <br />

        <div className={styles.box_text_2page}>
          <div className={styles.in_box_2page}>
            <span className={styles.text1}> - ดศ.ม.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses_offered2;
