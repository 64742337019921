import React from "react";
import styles from "../style/Report_a_complaint.module.css";
import report1 from "../images/report1.svg";
import report2 from "../images/report2.svg";
import report3 from "../images/report3.svg";

const Report_a_complaint = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_head}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          แจ้งเรื่อง
        </h1>
        <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
          ร้องเรียน
        </h1>
      </div>
      
      <div className={styles.btn_content}>
        <div className={styles.btn}>
          <div className={styles.icon_box}>
            <img src={report1} className={styles.icon} alt="icon 1" />
          </div>
          <div className={styles.text_btn_box}>
            <span className={styles.text1}>สายตรงคณบดี</span>
          </div>
          <div
            className={styles.button}
            onClick={() => {
              window.open("http://www.human.nu.ac.th/th/index-send.php");
              console.log("แจ้งร้องเรียน")
            }}
          >
            <span className={styles.text4}>คลิกที่นี่</span>
          </div>
        </div>

        <div className={styles.btn}>
          <div className={styles.icon_box}>
            <img src={report2} className={styles.icon} alt="icon 2" />
          </div>
          <div className={styles.text_btn_box}>
            <span className={styles.text1}>ประชาสัมพันธ์คณะมนุษยศาสตร์</span>
          </div>
          <a className={styles.text2} href="tel:055962035">
            0 5596 2035
          </a>
          <a className={styles.text2} href="mailto:humanadmission@nu.ac.th">
            humanadmission@nu.ac.th
          </a>
        </div>

        <div className={styles.btn}>
          <div className={styles.icon_box}>
            <img src={report3} className={styles.icon} alt="icon 2" />
          </div>
          <div className={styles.text_btn_box}>
            <span className={styles.text1}>เบอร์โทรฉุกเฉิน</span>
          </div>
          <span className={styles.text3}>กิจการนิสิตคณะมนุษยศาสตร์</span>
          <a className={styles.text2} href="tel:055962021">
            0 5596 2021
          </a>
          <span className={styles.text3}>โรงพยาบาลมหาวิทยาลัยนเรศวร</span>
          <a className={styles.text2} href="tel:055965666">
            055 965 666
          </a>
          <span className={styles.text3}>
            ศูนย์รับแจ้งเหตุฉุกเฉินนิสิต มหาวิทยาลัยนเรศวร
          </span>
          <a className={styles.text2} href="tel:+66869369977 ">
            +66 86 936 9977
          </a>
        </div>
      </div>
    </div>
  );
};

export default Report_a_complaint;
