import React from "react";
import styles from "../../style/Courses_offered.module.css";

const Courses_offered5 = () => {
  return (
    <div
      className={styles.container}
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      <div className={styles.box_text2}>
        <h1 className={styles.text_head} style={{ color: "#666666" }}>
          เงื่อนไขเกณฑ์การสอบผ่านความรู้
          <h1 className={styles.text_head} style={{ color: "#B28A4C" }}>
            ภาษาอังกฤษ
          </h1>
        </h1>
      </div>

      <div className={styles.box_text1}>
        <span className={styles.text1}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            "นิสิตระดับบัณฑิตศึกษา จะที่สำเร็จการศึกษา จะต้องมีคะแนนภาษาอังกฤษ ตามที่มหาวิทยาลัยกำหนด ดังนี้"
          }
        </span>

        <br />

        <div className={styles.box_text1}>
          <h1 className={styles.text_head_sub}>Paper based TOEFL</h1>
          <div className={styles.box_text1}>
            <span className={styles.text1}>- ระดับปริญาญาโท 417</span>
            <span className={styles.text1}>- ระดับปริญาญาเอก 500</span>
          </div>
        </div>
        <div className={styles.box_text1}>
          <h1 className={styles.text_head_sub}>Internet Based TOEFL</h1>
          <div className={styles.box_text1}>
            <span className={styles.text1}>- ระดับปริญาญาโท 35</span>
            <span className={styles.text1}>- ระดับปริญาญาเอก 60</span>
          </div>
        </div>
        <div className={styles.box_text1}>
          <h1 className={styles.text_head_sub}>
            International English Language Testing System (IELTS)
          </h1>
          <div className={styles.box_text1}>
            <span className={styles.text1}>- ระดับปริญาญาโท 5.0 </span>
            <span className={styles.text1}>- ระดับปริญาญาเอก 6.0</span>
          </div>
        </div>
        <div className={styles.box_text1}>
          <h1 className={styles.text_head_sub}>
            Chulalongkorn University Test of English Proficiency <br />{" "}
            (CU-TEP) 
          </h1>
          <div className={styles.box_text1}>
            <span className={styles.text1}>- ระดับปริญาญาโท 54</span>
            <span className={styles.text1}>- ระดับปริญาญาเอก 65</span>
          </div>
        </div>
        <div className={styles.box_text1}>
          <h1 className={styles.text_head_sub}>
            Cambridge English Placement Test (CEPT)
          </h1>
          <div className={styles.box_text1}>
            <span className={styles.text1}>- ระดับปริญาญาโท B1 (37)</span>
            <span className={styles.text1}>- ระดับปริญาญาเอก B2 (43)</span>
          </div>
        </div>
        <div className={styles.box_text1}>
          <h1 className={styles.text_head_sub}>
            Naresuan University Standard English Test(NU-SET) 
          </h1>
          <div className={styles.box_text1}>
            <span className={styles.text1}>- ระดับปริญาญาโท 85</span>
            <span className={styles.text1}>- รระดับปริญาญาเอก 92</span>
          </div>
        </div>
        <div className={styles.box_text1}>
          <h1 className={styles.text_head_sub}>
            Naresuan University Writing Proficiency Test(NU Writing) 
          </h1>
          <div className={styles.box_text1}>
            <span className={styles.text1}>- ระดับปริญาญาโท 60</span>
            <span className={styles.text1}>- ระดับปริญาญาเอก 70</span>
          </div>
        </div>
      </div>
      <br />

      <div style={{ width: "100%", height: 50 }}>&nbsp;</div>
    </div>
  );
};

export default Courses_offered5;
